html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
.bo {
  height: 100%;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 40px;
  background-color: #020f34;
  color: #ffffff;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  background-color: #020f34;
  color: #fff;
}

.titulo {
  color: #ded4d4;
}

.botonIngesar {
  color: #fff;
  background: rgb(253, 29, 29);
  background: linear-gradient(
    90deg,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fc4004;
  background-color: #020f34;
  color: #b4bcac;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

.form-signin input[type="datetime-local"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fc4004;
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin input[type="file"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fc4004;
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin input[type="file"]::-webkit-file-upload-button {
  margin-top: 1px;
  border: #020f34;
  border-radius: 10px;
  color: #fff;
  background: rgb(253, 29, 29);
  background: linear-gradient(
    90deg,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fc4004;
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin select {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fc4004;
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin select:after {
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin select:focus {
  background-color: #020f34;
  color: #b4bcac;
}

.item-s {
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin textarea {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fc4004;
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin textarea:after {
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin textarea:focus {
  background-color: #020f34;
  color: #b4bcac;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: #fc4004;
  background-color: #020f34;
  color: #b4bcac;
}

.cuadro {
  margin-bottom: 10px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-color: #fc4004 !important;
}

.circuloNaranja {
  background-color: #fc4004 !important;
  border-color: #f49852 !important;
}
